import React, {useState, useEffect, useRef} from 'react';
import {HomeGameCardItem} from '../home.type';
import {useInnerStyle} from '../home.hooks';
import theme from '@/style';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import {goTo, getUrlParams} from '@/utils';
import Text from '@/components/basic/text';
import {View, Image, ImageBackground, StyleSheet, FlatList} from 'react-native';
import LazyImage from '@/components/basic/image';
import {toGame} from '@/common-pages/game-navigate';
import {debounce} from '@/utils';
import BackArrow from '@/pages/svgs/backarrow';
import GoArrow from '@/pages/svgs/goarrow';
import Button from '@/components/basic/button';
import {useTranslation} from 'react-i18next';
import {SafeAny} from '@/types';
import ExposureWrap from '@/components/business/track-wrap/exposure-wrap';
import {trackClick} from '@/components/business/track-wrap/utils';

const NULL_ELE = {
  categoryId: -9999,
  gamePic: '',
  gamePrice: 0,
  gameType: '',
  gameUrl: '',
  id: 0,
  name: '',
  otherUrl: '',
  packageId: 0,
  playersNumber: 0,
  provider: '',
  sort: 0,
  source: '',
  tripartiteUniqueness: '',
  twoCategoryId: 0,
};

interface HomeFloorGamesProps {
  icon: string;
  title: string;
  list: HomeGameCardItem[];
  type: string;
}
const HomeFloorGames: React.FC<HomeFloorGamesProps> = ({
  list,
  icon,
  title,
  type,
}) => {
  const {i18n} = useTranslation();
  const flatlistRef = useRef();
  const [index, setIndex] = useState(0);
  const {
    size: {
      screenWidth,
      lottery: {hotGameCardHeight},
    },
  } = useInnerStyle();
  const styles = StyleSheet.create({
    titleBox: {
      height: (40 * screenWidth) / 375,
    },
    titleBtn: {
      height: (24 * screenWidth) / 375,
      width: (24 * screenWidth) / 375,
      borderRadius: (4 * screenWidth) / 375,
      borderColor: 'rgba(187, 187, 197, 0.50)',
      borderWidth: 1,
      marginLeft: (8 * screenWidth) / 375,
      backgroundColor: '#fff',
      opacity: 0.5,
    },
    activeBtn: {
      borderColor: 'rgba(187, 187, 197, 1)',
      opacity: 1,
    },
  });
  const [list9Item, setList9Item] = useState<
    {list: HomeGameCardItem[]; height: number}[]
  >([]);
  const len = useRef<number>(list.length);
  useEffect(() => {
    while (list.length % 3) {
      // 最后一行非3的倍数用空元素填满
      // @ts-ignore
      list.push(NULL_ELE);
    }
    let i = 0;
    const arr = [];
    while (list.length > i * 9) {
      const sliceList = list.slice(i * 9, ++i * 9);
      arr.push({
        list: sliceList,
        height:
          (hotGameCardHeight + theme.paddingSize.s) *
            Math.ceil(sliceList.length / 3) +
          theme.paddingSize.xxs,
      });
    }
    setList9Item(arr);
  }, [list, hotGameCardHeight]);
  const back = debounce(() => {
    if (index === 0) {
      return;
    }
    // @ts-ignore
    flatlistRef.current?.scrollToIndex({animated: true, index: index - 1});
  });

  const go = debounce(() => {
    if (index === Math.floor((list.length - 1) / 9)) {
      return;
    }
    // @ts-ignore
    flatlistRef.current?.scrollToIndex({animated: true, index: index + 1});
  });
  const goGames = () => {
    if (title === 'Lottery') {
      goTo('Lottery');
      return;
    }
    if (type === 'liveCasinoV2') {
      goTo('LiveCasino', {secondPage: '1', categoryId: 6});
    } else {
      goTo('Casino', {
        categoryId: 5,
        gameType: type === 'indianCasino' ? 'Table Games' : type,
      });
    }
  };
  const jumpGame = (v: HomeGameCardItem) => {
    // 空元素直接返回
    if (v.categoryId === -9999) {
      return;
    }
    if (type === 'lotteryV2' && v.gameType.toLowerCase() === 'dice') {
      const params = getUrlParams(v.gameUrl);
      params.cycle = v.name.split(' ')[1];
      goTo('Dice', params);
    } else if (type === 'lotteryV2' && v.gameType.toLowerCase() === 'color') {
      goTo('Color', getUrlParams(v.otherParams));
    } else {
      toGame(v);
    }
  };

  const onScroll = (e: SafeAny) => {
    let contentOffset = e.nativeEvent.contentOffset;
    let viewSize = e.nativeEvent.layoutMeasurement;
    let pageNum = Math.floor(contentOffset.x / viewSize.width);
    setIndex(pageNum);
  };

  const renderItem = ({
    item,
  }: {
    item: {list: HomeGameCardItem[]; height: number};
  }) => {
    const {list: itemList, height} = item;
    return (
      <View
        style={[
          theme.flex.wrap,
          theme.flex.row,
          {
            width: screenWidth,
            height,
            gap: (theme.paddingSize.s * screenWidth) / 375,
            paddingHorizontal: (theme.paddingSize.l * screenWidth) / 375,
            paddingBottom: (theme.paddingSize.l * screenWidth) / 375,
          },
        ]}>
        {itemList.map((v, i) => {
          return (
            <ExposureWrap
              key={i}
              message={
                v.categoryId === -9999
                  ? ''
                  : `HOME_${type.replace(/\s/g, '').toUpperCase()}_${
                      index * 9 + i + 1
                    }_EXPOSURE`
              }>
              <NativeTouchableOpacity
                onPress={() => {
                  if (v.categoryId !== -9999) {
                    trackClick(
                      `HOME_${type.replace(/\s/g, '').toUpperCase()}_${
                        index * 9 + i + 1
                      }_TAP`,
                    );
                  }
                  jumpGame(v);
                }}
                style={[
                  theme.borderRadius.m,
                  theme.overflow.hidden,
                  // eslint-disable-next-line react-native/no-inline-styles
                  {
                    height: (screenWidth * 155.67) / 375,
                    // 25%加上边距后,一行不会超过3个,但也不至于少于2个
                    // 所以flex1+minwidth25%可以视为一行三个
                    minWidth: '25%',
                    flex: 1,
                    backgroundColor:
                      v.categoryId === -9999
                        ? 'rgba(0,0,0,0)'
                        : theme.backgroundColor.palegrey,
                  },
                ]}>
                {v.categoryId !== -9999 && (
                  <LazyImage
                    imageUrl={v.otherUrl! || v.gamePic}
                    width={'100%'}
                    height={'100%'}
                  />
                )}
              </NativeTouchableOpacity>
            </ExposureWrap>
          );
        })}
      </View>
    );
  };

  return (
    <View>
      <ImageBackground
        style={[
          styles.titleBox,
          theme.padding.lrl,
          theme.flex.row,
          theme.flex.centerByCol,
        ]}
        source={
          type === 'lotteryV2'
            ? require('@assets/imgs/home/home-floor-title-most-popular-bg.webp')
            : require('@assets/imgs/home/home-floor-title-bg.webp')
        }>
        {icon ? (
          <Image
            source={{uri: icon}}
            style={[
              {
                width: (theme.iconSize.xxl * screenWidth) / 375,
                height: (theme.iconSize.xxl * screenWidth) / 375,
                marginRight: (theme.paddingSize.s * screenWidth) / 375,
              },
            ]}
          />
        ) : (
          <View
            style={[
              theme.icon.xxl,
              theme.margin.rights,
              theme.background.palegrey,
            ]}
          />
        )}
        <Text calc size="medium" main blod onPress={goGames}>
          {title}
        </Text>
        {type !== 'lotteryV2' && (
          <View
            style={[
              theme.flex.flex1,
              theme.flex.row,
              theme.flex.end,
              theme.flex.centerByCol,
            ]}>
            {type === 'hotGames' && (
              <Text calc primary fontWeight="700">
                {len.current}
              </Text>
            )}
            {type === 'hotGames' && (
              <Text calc second fontWeight="700">
                {' Games'}
              </Text>
            )}
            {type !== 'hotGames' && (
              <ExposureWrap
                message={`HOME_${type
                  .replace(/\s/g, '')
                  .toUpperCase()}_ALLGAMESBUTTON_EXPOSURE`}>
                <Button
                  buttonStyle={[
                    theme.padding.tbxxs,
                    theme.padding.lrxs,
                    theme.background.white,
                    // eslint-disable-next-line react-native/no-inline-styles
                    {
                      borderColor: theme.fontColor.secAccent,
                      borderWidth: 1,
                    },
                  ]}
                  onPress={() => {
                    trackClick(
                      `HOME_${type
                        .replace(/\s/g, '')
                        .toUpperCase()}_ALLGAMESBUTTON_TAP`,
                    );
                    goGames();
                  }}
                  radius={theme.borderRadiusSize.xs}>
                  <Text second fontWeight="700" calc>
                    {i18n.t('home.label.allGames')}
                  </Text>
                </Button>
              </ExposureWrap>
            )}
            <NativeTouchableOpacity
              onPress={back}
              style={[
                styles.titleBtn,
                theme.flex.flex,
                theme.flex.center,
                index !== 0 && styles.activeBtn,
              ]}>
              <BackArrow active={index !== 0} />
            </NativeTouchableOpacity>
            <NativeTouchableOpacity
              onPress={go}
              style={[
                styles.titleBtn,
                theme.flex.flex,
                theme.flex.center,
                index !== Math.floor((list.length - 1) / 9) && styles.activeBtn,
              ]}>
              <GoArrow active={index !== Math.floor((list.length - 1) / 9)} />
            </NativeTouchableOpacity>
          </View>
        )}
      </ImageBackground>
      <FlatList
        // @ts-ignore
        ref={flatlistRef}
        data={list9Item}
        keyExtractor={(_, i) => '' + i}
        renderItem={renderItem}
        horizontal={true}
        showsHorizontalScrollIndicator={false}
        onScroll={onScroll}
        pagingEnabled={true}
        getItemLayout={(data, i) => ({
          length: screenWidth,
          offset: screenWidth * i,
          index: i,
        })}
        scrollEventThrottle={500}
      />
    </View>
  );
};

export default HomeFloorGames;
