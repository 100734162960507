import React from 'react';
import {Image} from 'antd-mobile';
import {useTranslation} from 'react-i18next';
import HomeBottomCard from '@/components/business/home-bottom-card/home-bottom-card';
import licenses1 from '@assets/imgs/home/licenses1.webp';
import licenses2 from '@assets/imgs/home/licenses2.webp';

const HomeLicenses = () => {
  const {t} = useTranslation();
  const imgs = [licenses1, licenses2];
  return (
    <HomeBottomCard
      title={t('home.label.licenses')}
      gap={'gap-4'}
      imgs={imgs}
      renderItem={url => (
        <div className="h-[2.125rem]">
          <Image src={url} height={'2.125rem'} width={'2.125rem'} />
        </div>
      )}
    />
  );
};

export default HomeLicenses;
