import React from 'react';
import {SportsCategory, SportsItem} from '../hot-sports-category';
import styles from '../index.module.scss';
import {Image} from 'antd-mobile';
import {useTranslation} from 'react-i18next';
import globalStore from '@/services/global.state';
export interface SportSwiperItemProps {
  category: Omit<SportsCategory, 'list'>;
  item: SportsItem;
}

const SportSwiperItem = ({category, item}: SportSwiperItemProps) => {
  const {t} = useTranslation();
  const vsScores = item.vsScore?.split(':').map(item => item || 0) || [0, 0];
  return (
    <div
      className={`flex flex-col gap-4 p-2 w-[19rem] flex-none rounded-lg ${styles['sports-swiper-item']}`}
      onClick={() => {
        globalStore.openSports.next(item.uri);
      }}>
      <div className="flex flex-row items-center justify-between gap-3">
        <div className="flex flex-row items-center gap-2 flex-1 overflow-hidden">
          <Image src={category.icon} alt="icon" className="size-4 flex-none" />
          <span className="text-t2-dark text-xs overflow-hidden text-ellipsis whitespace-nowrap flex-1">
            {item.title}
          </span>
        </div>
        <div className="flex flex-row items-center flex-none">
          <div className="bg-black/30 rounded-sm h-[1.125rem] flex px-1 flex-row items-center text-t2-dark text-xs">
            {item.vsType}
          </div>
          <Image
            src={require('@components/assets/icons/mix-lotto/live.gif')}
            className="size-[1.125rem]"
          />
        </div>
      </div>
      <div className="flex flex-row items-center gap-3">
        <div className="flex flex-col flex-1 overflow-hidden">
          <Image
            src={item.vs[0].teamPic}
            fallback={
              category.icon ? (
                <Image src={category.icon} className="size-9" />
              ) : undefined
            }
            className="size-9"
          />
          <span className="w-full text-t2-dark font-bold overflow-hidden text-ellipsis whitespace-nowrap">
            {item.vs[0].teamName}
          </span>
        </div>
        <div className="flex flex-row flex-none items-center gap-1 font-bold">
          <div className="py-3 text-base text-white text-center w-8 border border-white/50 rounded bg-white/30">
            {vsScores[0]}
          </div>
          <div className="text-white text-sm">:</div>
          <div className="py-3 text-base text-white text-center w-8 border border-white/50 rounded bg-white/30">
            {vsScores[1]}
          </div>
        </div>
        <div className="flex flex-col flex-1 items-end overflow-hidden">
          <Image
            src={item.vs[1].teamPic}
            fallback={
              category.icon ? (
                <Image src={category.icon} className="size-9" />
              ) : undefined
            }
            className="size-9"
          />
          <span className="w-full text-t2-dark font-bold text-right overflow-hidden text-ellipsis whitespace-nowrap">
            {item.vs[1].teamName}
          </span>
        </div>
      </div>
      <div className="flex flex-row gap-1">
        {item.marketList && item.marketList.length <= 3 ? (
          item.marketList.map((market, index) => (
            <div
              className="flex flex-row flex-1 justify-between px-2 py-1.5 bg-white/30 text-white text-sm rounded"
              key={index}>
              <span>{market.outcomeName}</span>
              <span>{market.price}</span>
            </div>
          ))
        ) : (
          <div className="flex flex-row flex-1 justify-center px-2 py-1.5 bg-white/30 text-white text-sm rounded">
            <span>
              {t('home.label.availOutcomes', {
                count: item.marketList?.length || 0,
              })}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default SportSwiperItem;
