import globalStore from '@/services/global.state';
import theme from '@/style';
import React, {useCallback, useState} from 'react';
import {RefreshControl, ScrollView, StatusBar, View} from 'react-native';
import HomeHeader from './components/home-header';
import DownloadBanner from './components/home-download-banner';
import HomeBanner from './components/home-banner-old';
import HomeGoldArea from './components/home-gold-area';
import HomeFloorGames from './components/home-floor-games';
import HomeWinningInformation from './components/home-winning-information';
import HomeSide from './components/home-side';
import {getHomeGames, getNoticeCheck} from './home.service';
import {BasicObject} from '@types';
import {HomeGameFloorItem} from './home.type';
import {useFocusEffect} from '@react-navigation/native';
import {useInnerStyle} from './home.hooks';
import {FadeInView} from '@/components/basic/animations';
import {useResponsiveDimensions} from '@/utils';
import HomeFloorLottery from './components/home-floor-lottery';
import HomePartners from './components/home-partners';
import HomePays from './components/home-pays';
import HomeSocial from './components/home-social';
import HomeLicenses from './components/home-licenses';
import {Image} from 'antd-mobile';
import DownImg from '@assets/imgs/home/down-img.webp';
import {useTranslation} from 'react-i18next';
import {downloadApk, stopBeforeDownload} from '@/utils';
import {getSportsList} from '../gr8-sports/service';
import {SportSwiperItemProps} from '../gr8-sports/components/swiper-item';
import {convertToSportsSwiperItemList} from '../gr8-sports/converts';
import SportSwiper from '../gr8-sports/components/sport-swiper';

const Home = () => {
  const {i18n} = useTranslation();
  const [show, setShow] = useState(false);
  const [homeGames, setHomeGames] = useState<HomeGameFloorItem[]>();
  const {spaceAreaStyles} = useInnerStyle();
  const [refreshing, setRefreshing] = useState(false);
  const [version, setVersion] = useState(0);
  const [sportsList, setSportsList] = useState<SportSwiperItemProps[]>();
  const [noticeMap, setNoticeMap] = useState<BasicObject>({
    FREE_LOTTERY: 0,
    REBATE: 0,
    LUCKY_SPIN: 0,
  });

  const doNotice = (token: string | null) => {
    if (token) {
      getNoticeCheck().then(noticeList => {
        const newNoticeMap: BasicObject = {};
        Object.keys(noticeMap).forEach(key => {
          const notice = noticeList.find(item => item.noticeKey === key);
          if (notice) {
            newNoticeMap[key] = notice.noticeCount;
          }
        });
        setNoticeMap(newNoticeMap);
      });
    } else {
      setNoticeMap({
        FREE_LOTTERY: 0,
        REBATE: 0,
        LUCKY_SPIN: 0,
      });
    }
  };
  React.useEffect(() => {
    StatusBar.setBackgroundColor(theme.basicColor.primary);
    StatusBar.setBarStyle('dark-content');
    init();
    getSportsList().then(sports => {
      setSportsList(convertToSportsSwiperItemList(sports));
    });
  }, []);
  const init = (loading: boolean = true) => {
    loading && globalStore.globalLoading.next(true);
    return getHomeGames()
      .then(games => {
        setHomeGames(games);
      })
      .finally(() => globalStore.globalLoading.next(false));
  };
  const handleFocusEffect = useCallback(() => {
    const sub = globalStore.tokenSubject.subscribe(token => {
      doNotice(token);
      if (token) {
        globalStore.amountCheckOut.next({});
      }
    });
    return () => {
      sub.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useFocusEffect(handleFocusEffect);
  const handleRefresh = () => {
    setRefreshing(true);
    setVersion(_v => _v + 1);
    Promise.allSettled([init(false), doNotice(globalStore.token)]).finally(() =>
      setRefreshing(false),
    );
  };
  const {width: screenWidth} = useResponsiveDimensions();
  const onClickDownloadBtn = () => {
    if (stopBeforeDownload()) {
      return;
    }
    downloadApk();
  };
  React.useEffect(() => {
    setShow(true);
  }, []);
  return (
    <FadeInView
      style={[
        theme.fill.fill,
        {
          minWidth: screenWidth,
        },
        theme.flex.col,
      ]}>
      <HomeHeader />
      {globalStore.isWeb ? <DownloadBanner /> : null}
      <ScrollView
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={handleRefresh} />
        }>
        <div className="bg-white">
          <View style={{height: theme.paddingSize.l}} />
          <HomeBanner version={version} />
          <HomeGoldArea
            noticeMap={noticeMap}
            onNotice={() => doNotice(globalStore.token)}
          />
          <SportSwiper list={sportsList || []} />
          {homeGames?.map((floor, index) => {
            if (
              floor.uniqueKey === 'lotteryV2' &&
              floor.gamesList[3].gameUrl === '*'
            ) {
              return (
                <HomeFloorLottery
                  key={index}
                  title={floor.name}
                  icon={floor.categoryPic}
                />
              );
            }
            return floor.gamesList ? (
              <HomeFloorGames
                type={floor.uniqueKey}
                key={index}
                title={floor.name}
                icon={floor.categoryPic}
                list={floor.gamesList || []}
              />
            ) : null;
          })}
          <HomeWinningInformation version={version} />
        </div>
        <div className="w-full pb-10 bg-white">
          <HomePartners />
          <div className="h-[0.0625rem]" />
          <HomePays />
          <div className="h-[0.0625rem]" />
          <HomeSocial />
          <div className="h-[0.0625rem]" />
          <HomeLicenses />
        </div>
        <View
          style={[
            theme.fill.fillW,
            spaceAreaStyles.bottom,
            {backgroundColor: '#fff'},
          ]}
        />
      </ScrollView>
      <HomeSide
        noticeMap={noticeMap}
        onNotice={() => doNotice(globalStore.token)}
      />
      {show && (
        <div className="fixed bottom-[5.125rem] w-full m-auto flex flex-row justify-center">
          <div
            className="flex py-2 px-[0.875rem] items-center gap-2 rounded-[2.75rem] bg-[--1]"
            style={{
              boxShadow: '0px 1px 12px 0px rgba(0, 0, 0, 0.25)',
            }}>
            <div className="w-[1.75rem] h-[1.75rem]">
              <Image src={DownImg} className="w-[1.75rem] h-[1.75rem]" />
            </div>
            <span
              className="text-white text-sm font-bold leading-[66.857%]"
              onClick={() => {
                onClickDownloadBtn();
                setShow(false);
              }}
              style={{
                textShadow: '0px 0.78px 0.39px rgba(71, 129, 255, 0.50)',
              }}>
              {i18n.t('home.label.downText')}
            </span>
            <div className="w-[0.0625rem] h-6 bg-white opacity-50" />
            <div onClick={() => setShow(false)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="18"
                viewBox="0 0 19 18"
                fill="none">
                <rect
                  x="6.39258"
                  y="15.3203"
                  width="3"
                  height="13.3333"
                  rx="1.5"
                  transform="rotate(-135 6.39258 15.3203)"
                  fill="white"
                />
                <rect
                  x="15.584"
                  y="13.1992"
                  width="3"
                  height="13.3333"
                  rx="1.5"
                  transform="rotate(135 15.584 13.1992)"
                  fill="white"
                />
              </svg>
            </div>
          </div>
        </div>
      )}
    </FadeInView>
  );
};
export default Home;
