import React, {useCallback, useEffect, useState} from 'react';
import {
  NewGameListItem,
  // postNewGameList,
  // getGameList,
  getCasinoGames,
} from '@/common-pages/casino/casino.service';
// import {getCategories} from '@/common-pages/live-casino/live-casino-service';
import globalStore from '@/services/global.state';
import DetailNavTitle from '@businessComponents/detail-nav-title';
import Wallet from '@/common-pages/wallet';
import {BasicObject, NavigatorScreenProps} from '@/types';
import {useFocusEffect} from '@react-navigation/native';
import {useTranslation} from 'react-i18next';
import CasinoTabs from './casino-tabs';
import CasinoAll from './casino-all';
import CasinoContent from './casino-content';

const Casino = (props: NavigatorScreenProps) => {
  const [gameTypes, setGameTypes] = useState<
    {label: string; value: string; icon?: string}[]
  >([]);
  const {i18n} = useTranslation();
  const {route} = props;
  // const homeCategoryId = (route.params as BasicObject)?.categoryId;
  const homeGameType = (route.params as BasicObject)?.gameType;
  const [gameType, setGameType] = useState<string>('ALL');
  const [gameList, setGameList] = useState<NewGameListItem[][]>([]);
  // const [gamesState, setGamesState] = useState<
  //   {
  //     pageNo: number;
  //     hasMore: boolean;
  //   }[]
  // >([]);
  const [loading, setloading] = useState(true);

  const getGameCategories = async () => {
    console.log(9);
    try {
      const res = await getCasinoGames();
      console.log('res', res);
      if (res && res.length) {
        const types = [
          {
            label: 'ALL',
            value: 'ALL',
          },
          ...res.map(v => ({
            label: v.name,
            value: v.name,
            icon: v.icon,
            selectIcon: v.categoryPic,
          })),
        ];
        setGameType(
          types.some(v => v.value === homeGameType) ? homeGameType : 'ALL',
        );
        setGameTypes(types);
        setGameList(res.map(v => v.gamesList || []));
      }
    } catch (e) {
      console.log('获取游戏分类失败', e);
    }
    setloading(false);
  };

  const handleFocusEffect = useCallback(() => {
    const sub = globalStore.tokenSubject.subscribe(token => {
      if (token) {
        globalStore.amountCheckOut.next({});
      }
    });
    return () => {
      sub.unsubscribe();
    };
  }, []);
  useFocusEffect(handleFocusEffect);

  // const getList = useCallback(
  //   (pageNo: number, index: number, showloading = true) => {
  //     showloading && globalStore.globalLoading.next(true);
  //     return postNewGameList({
  //       categoryId: homeCategoryId,
  //       pageNo: pageNo,
  //       gameType,
  //       pageSize: 21,
  //     })
  //       .then(game => {
  //         setGameList(list => {
  //           const copyList = JSON.parse(JSON.stringify(list));
  //           copyList[index] = [...copyList[index], ...(game.content || [])];
  //           return copyList;
  //         });
  //         setGamesState(list => {
  //           const copyList = JSON.parse(JSON.stringify(list));
  //           copyList[index].pageNo += 1;
  //           return copyList;
  //         });
  //       })
  //       .finally(() => {
  //         globalStore.globalLoading.next(false);
  //       });
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [homeCategoryId, gameList, gameType],
  // );

  // const refreshNextPage = (index: number) => {
  //   const hasMore = gamesState[index].hasMore;
  //   if (!hasMore) {
  //     return Promise.resolve();
  //   }
  //   const pageNo = gamesState[index].pageNo;
  //   globalStore.globalLoading.next(true);
  //   return getList(pageNo + 1, index, true);
  // };

  useEffect(() => {
    setloading(true);
    getGameCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homeGameType]);

  return (
    <>
      <DetailNavTitle
        hideTitle
        hideAmount
        hideServer
        containerStyle={{
          // @ts-ignore
          boxShadow: '0 0 0 0 rgba(204, 206, 228, 0)',
        }}
        leftNode={
          <div className="text-t1 text-lg font-bold">
            {i18n.t('home.title.casino')}
          </div>
        }
        rightNode={<Wallet theme="dark" />}
      />
      {!loading && (
        <CasinoTabs
          tabOptions={gameTypes}
          value={gameType}
          onChange={setGameType}
          render={(v: string, index: number) =>
            v === 'ALL' ? (
              <CasinoAll
                gameList={gameList}
                gameTypes={gameTypes}
                setGameType={setGameType}
              />
            ) : (
              <CasinoContent
                gameList={gameList[index]}
                // refreshNextPage={() => refreshNextPage(index)}
                // hasMore={gamesState[index]?.hasMore}
                gameType={gameType}
              />
            )
          }
        />
      )}
    </>
  );
};

export default Casino;
