import React, {useMemo} from 'react';
import {NewGameListItem} from '@/common-pages/casino/casino.service';
import NoData from '@/components/basic/error-pages/no-data';
import CasinoCategory from './casino-category';
import CasinoIcons from './casino-icons';
import {Image} from 'antd-mobile';
import {toGame} from '@/common-pages/game-navigate';

interface CasinoContentProps {
  gameList: NewGameListItem[][];
  gameTypes: {label: string; value: string; icon?: string}[];
  setGameType: (value: string) => void;
}

const CasinoAll: React.FC<CasinoContentProps> = ({
  gameList,
  gameTypes,
  setGameType,
}) => {
  const titles = useMemo(() => {
    const [, ...rest] = gameTypes;
    return rest;
  }, [gameTypes]);
  if (!gameList.length) {
    return <NoData />;
  }
  return (
    <div className="flex flex-col gap-2 overflow-auto pb-10 h-[calc(100vh-9.375rem)]">
      {gameList.map((list, index) => {
        const isWide = list[0]?.gameType === 'Live Casino';
        return (
          <div key={index}>
            <CasinoCategory
              icon={
                titles[index].icon ? (
                  <div className="w-6 h-6">
                    <Image
                      width={'1.5rem'}
                      height={'1.5rem'}
                      src={titles[index].icon}
                    />
                  </div>
                ) : (
                  <CasinoIcons name={titles[index].value} checked={false} />
                )
              }
              title={titles[index].label}
              list={list}
              onClick={() => {
                setGameType(titles[index].value);
              }}
              swiperSlideClassName={isWide ? 'w-[10.5rem]' : 'w-[7rem]'}
              renderItem={item => {
                return (
                  <div
                    onClick={() => toGame(item)}
                    className={`${
                      isWide ? 'w-[10.5rem]' : 'w-[7rem]'
                    } pb-2 rounded-t-lg  overflow-hidden flex flex-col gap-1 bg-white`}>
                    <img
                      src={item.otherUrl || item.gamePic}
                      style={{
                        width: '100%',
                        height: isWide ? '7.875rem' : '7rem',
                        objectFit: 'cover',
                        objectPosition: 'top',
                      }}
                    />
                    <span
                      className="text-t1 text-sm truncate px-2"
                      title={item.name}>
                      {item.name}
                    </span>
                  </div>
                );
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default CasinoAll;
