import React, {useMemo} from 'react';
// import {InfiniteScroll, DotLoading} from 'antd-mobile';
import {NewGameListItem} from '@/common-pages/casino/casino.service';
import NoData from '@/components/basic/error-pages/no-data';
import {NoMoreData} from '@basicComponents/default-page';
import {toGame} from '@/common-pages/game-navigate';

interface CasinoContentProps {
  gameList: NewGameListItem[];
  // refreshNextPage: () => Promise<void>;
  // hasMore: boolean;
  gameType: string;
}

const WideCard = ({item}: {item: NewGameListItem}) => {
  return (
    <div
      onClick={() => toGame(item)}
      className="w-[11rem] flex flex-col gap-1 pb-2 rounded-lg bg-white overflow-hidden">
      <img
        src={item.otherUrl || item.gamePic}
        style={{
          width: '100%',
          height: '8.25rem',
          objectFit: 'cover',
          objectPosition: 'top',
        }}
      />
      <div className="flex flex-col gap-1 px-2">
        <span className="text-t1 text-sm truncate" title={item.name}>
          {item.name}
        </span>
        <span className="text-t3 text-xs truncate" title={item.provider}>
          {item.provider}
        </span>
      </div>
    </div>
  );
};

const NormalCard = ({item}: {item: NewGameListItem}) => {
  return (
    <div
      onClick={() => toGame(item)}
      className="w-[7rem] flex flex-col gap-1 pb-2 rounded-lg bg-white overflow-hidden">
      <img
        src={item.otherUrl || item.gamePic}
        style={{
          width: '100%',
          height: '8.25rem',
          objectFit: 'cover',
          objectPosition: 'top',
        }}
      />
      <span className="text-t1 text-sm truncate" title={item.name}>
        {item.name}
      </span>
    </div>
  );
};

const CasinoContent: React.FC<CasinoContentProps> = ({
  gameList,
  // refreshNextPage,
  // hasMore,
  gameType,
}) => {
  const list = useMemo(() => {
    if (!gameList?.length) {
      return [];
    }
    const num = gameType.toLowerCase().trim().startsWith('live casino') ? 2 : 3;
    return gameList.reduce<NewGameListItem[][]>((acc, _, index) => {
      if (index % num === 0) {
        acc.push(gameList.slice(index, index + num));
      }
      return acc;
    }, []);
  }, [gameType, gameList]);
  if (!list.length) {
    return <NoData />;
  }
  return (
    <>
      <div className="px-2 gap-2 flex flex-col overflow-auto pb-10 h-[calc(100vh-9.375rem)]">
        {list.map((item, index) => (
          <div className="gap-2 flex flex-row" key={index}>
            {item.map((v, i) =>
              gameType.toLowerCase().trim().startsWith('live casino') ? (
                <WideCard item={v} key={i} />
              ) : (
                <NormalCard item={v} key={i} />
              ),
            )}
          </div>
        ))}
      </div>
      <NoMoreData />
      {/* <InfiniteScroll loadMore={refreshNextPage} hasMore={hasMore}>
        <>
          {hasMore ? (
            <>
              <span>Loading</span>
              <DotLoading />
            </>
          ) : (
            <NoMoreData />
          )}
        </>
      </InfiniteScroll> */}
    </>
  );
};

export default CasinoContent;
