import React, {useMemo} from 'react';
import {Tabs, Image} from 'antd-mobile';
import CasinoIcons from './casino-icons';
import './style.scss';

export interface CasinoTabsProps {
  tabOptions: {
    label: string;
    value: string;
    icon?: string;
    selectIcon?: string;
  }[];
  value: string;
  render: (value: string, index: number) => React.ReactNode;
  onChange: (value: string) => void;
}

const CasinoTabs: React.FC<CasinoTabsProps> = ({
  tabOptions,
  value,
  render,
  onChange,
}) => {
  const resultTabOptions = useMemo(() => {
    return tabOptions.map(({label, value: innerVal, icon, selectIcon}) => ({
      title: (
        <div
          className={`flex px-3 py-1 justify-center flex-row gap-1 items-center rounded-lg ${
            value === innerVal ? 'bg-c1' : 'bg-[#F5F4F1]'
          }`}>
          {icon && selectIcon ? (
            <div className="w-6 h-6 relative">
              <div
                className={`w-6 h-6 absolute top-0 left-0 ${
                  value === innerVal ? 'z-10' : 'z-0'
                }`}>
                <Image width={'1.5rem'} height={'1.5rem'} src={selectIcon} />
              </div>
              <div
                className={`w-6 h-6 absolute top-0 left-0 ${
                  value === innerVal ? 'z-0' : 'z-10'
                }`}>
                <Image width={'1.5rem'} height={'1.5rem'} src={icon} />
              </div>
            </div>
          ) : (
            <CasinoIcons name={label} checked={value === innerVal} />
          )}
          <span
            className={`text-t1 text-xs font-bold ${
              value === innerVal ? 'text-white' : 'text-t3'
            }`}>
            {label}
          </span>
        </div>
      ),
      key: innerVal,
    }));
  }, [tabOptions, value]);
  return (
    <div className="casino-tabs">
      <Tabs activeKey={value} onChange={onChange}>
        {resultTabOptions.map(({title, key}, index) => (
          <Tabs.Tab title={title} key={key}>
            {render(key, index - 1)}
          </Tabs.Tab>
        ))}
      </Tabs>
    </div>
  );
};

export default CasinoTabs;
