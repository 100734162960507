import React from 'react';
import {Image} from 'antd-mobile';
import {useTranslation} from 'react-i18next';
import HomeBottomCard from '@/components/business/home-bottom-card/home-bottom-card';
import partner1 from '@assets/imgs/home/partner1.webp';
import partner2 from '@assets/imgs/home/partner2.webp';
import partner3 from '@assets/imgs/home/partner3.webp';
import partner4 from '@assets/imgs/home/partner4.webp';

const HomePartners = () => {
  const {t} = useTranslation();
  const imgs = [partner1, partner2, partner3, partner4];
  return (
    <HomeBottomCard
      title={t('home.label.partners')}
      cols={3}
      imgs={imgs}
      renderItem={url => (
        <div className="h-10">
          <Image src={url} style={{height: 40}} />
        </div>
      )}
    />
  );
};

export default HomePartners;
