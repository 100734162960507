import {envConfig} from '@/utils';
import {SportsCategory, Event, SportsListItem} from './service';
import {SportsItem, SportsType} from './hot-sports-category';
import {SportSwiperItemProps} from './components/swiper-item';

export function getSportsCategory(
  sportsName: string,
  category: SportsCategory,
): SportsItem {
  const categoryName = category.Name.en;
  const tour = category.Tournaments[0];
  const tourName = tour.Name.en;
  const event = tour.Events[0];
  const versus = event.Competitors.map(item => ({
    teamName: item.Name.en,
    teamPic: `${envConfig.gr8IframeUrl}taxonomyicons/competitors/${item.Id}-164w`,
  }));
  const matchInfo = getMatchInfo(sportsName, event);
  return {
    title: `${tourName}. ${categoryName}. ${sportsName}`,
    // 必然是直播赛事，所以这边不展示这些小部件
    hasLiveMatch: false,
    vs: versus,
    ...matchInfo,
  };
}

const allSportFullPeriodMap: Record<string, number> = {
  Basketball: 8,
  Football: 4,
  Volleyball: 1,
  CyberSport: 1,
};

function getSlug(url: string) {
  const urlArr = url.split('/');
  for (let i = 0; i < urlArr.length; i++) {
    if (urlArr[i] === 'events') {
      return urlArr[i + 1];
    }
  }
  return '';
}

function getMatchInfo(sportsName: string, event: Event) {
  const market = event.Markets[0];
  const periodName = market.PeriodName.en;
  const matchingPeriodId = market.MarketKey.Period;
  const fullPeriodId = allSportFullPeriodMap[sportsName] || 1;
  const periods = event.PeriodScores;
  const matchPeriod = periods.find(item => item.Period === matchingPeriodId);
  const fullPeriod = periods.find(item => item.Period === fullPeriodId);
  const urlFirst = Object.values(event.URL)[0];
  const slug = getSlug(urlFirst);

  return {
    vsType: periodName,
    vsTotalScore: fullPeriod?.Score.split('-').join(':'),
    vsScore: matchPeriod ? matchPeriod.Score.split('-').join(' : ') : '0 : 0',
    uri: `/events/${slug}`,
    marketList: market.Outcomes.map((item, index, arr) => ({
      price: item.Price + '',
      outcomeName:
        arr.length === 2
          ? index + 1 + ''
          : arr.length === 3
          ? ['1', 'Draw', '2'][index]
          : item.OutcomeName.en,
    })),
  };
}

const sportsIconMap: Record<string, string> = {
  Basketball: require('@/assets/icons/sports/basketball.webp'),
  Football: require('@/assets/icons/sports/football.webp'),
  Volleyball: require('@/assets/icons/sports/volleyball.webp'),
  CyberSport: require('@/assets/icons/sports/cybersport.webp'),
};

export function convertToSportsCategory(sportsListItem: SportsListItem) {
  const {Sport, Categories} = sportsListItem;
  return {
    title: Sport,
    icon: sportsIconMap[Sport],
    type: Sport as SportsType,
    list: Categories.map(item => getSportsCategory(Sport, item)),
  };
}

export function convertToSportsSwiperItemList(
  sportsListItemArr: SportsListItem[] = [],
) {
  let _sportsListItemArr = Array.from(sportsListItemArr);
  let indexList = Array.from({length: _sportsListItemArr.length}, () => 0);
  let itemList: SportSwiperItemProps[] = [];
  while (true) {
    const hasNext = indexList.length > 0;
    if (!hasNext) {
      return itemList;
    }
    const checkIndex = Math.floor(Math.random() * indexList.length);
    const sportsListItem = _sportsListItemArr[checkIndex];
    const {Sport, Categories} = sportsListItem;
    const item = Categories[indexList[checkIndex]];
    itemList.push({
      category: {
        title: Sport,
        type: Sport as SportsType,
        icon: sportsIconMap[Sport],
      },
      item: getSportsCategory(Sport, item),
    });
    indexList[checkIndex]++;
    if (indexList[checkIndex] >= Categories.length) {
      indexList.splice(checkIndex, 1);
      _sportsListItemArr.splice(checkIndex, 1);
    }
  }
}
