import {sportsHttp} from '@/utils';

type Gr8Language = 'en';
type LanguageMap<T> = Record<Gr8Language, T>;

export interface MarketKey {
  AdditionalValues: [];
  Period: number;
  MarketValue: string;
  MarketType: number;
}

export interface Outcome {
  Price: number;
  OutcomeKey: string;
  OutcomeName: LanguageMap<string>;
}

export interface Market {
  LineItemId: string;
  MarketKey: MarketKey;
  MarketName: LanguageMap<string>;
  Outcomes: Outcome[];
  PeriodName: LanguageMap<string>;
}

export interface Competitor {
  Id: string;
  Name: LanguageMap<string>;
}

export interface PeriodScore {
  Period: number;
  Type: string;
  Score: string;
}

export interface Event {
  Competitors: Competitor[];
  Id: string;
  Markets: Market[];
  Name: LanguageMap<string>;
  PeriodScores: PeriodScore[];
  Stage: string;
  StartTime: string;
  StartTimeUnixFormat: number;
  URL: Record<string, string>;
}

export interface Tournament {
  Id: string;
  Name: LanguageMap<string>;
  Events: Event[];
}

export interface SportsCategory {
  Id: string;
  Name: LanguageMap<string>;
  Tournaments: Tournament[];
}

export interface SportsListItem {
  Categories: SportsCategory[];
  Sport: string;
  SportId: number;
}

export function getSportsList() {
  return sportsHttp.get<null, SportsListItem[]>('gr8/api/v2/feed/prematch');
}
