import React from 'react';
import {Image} from 'antd-mobile';
import {useTranslation} from 'react-i18next';
import HomeBottomCard from '@/components/business/home-bottom-card/home-bottom-card';
import pay1 from '@assets/imgs/home/upi.webp';
import pay2 from '@assets/imgs/home/paytm.webp';
import pay3 from '@assets/imgs/home/seppay.webp';
import pay4 from '@assets/imgs/home/bhim.webp';
import pay5 from '@assets/imgs/home/phonepe.webp';
import pay6 from '@assets/imgs/home/okpay.webp';

const HomePays = () => {
  const {t} = useTranslation();
  const imgs = [pay1, pay2, pay3, pay4, pay5, pay6];
  return (
    <HomeBottomCard
      title={t('home.label.pays')}
      cols={3}
      imgs={imgs}
      renderItem={url => (
        <div className="h-[2.625rem]">
          <Image src={url} height={'2.625rem'} />
        </div>
      )}
      px={'px-3'}
    />
  );
};

export default HomePays;
