import {
  ClassicIcon,
  AllIcon,
  NewReleasesIcon,
  SlotsIcon,
  TableGamesIcon,
  FishGamesIcon,
  OthersIcon,
  LiveCasinoIcon,
} from './svg.variable';
import theme from '@/style';
import React from 'react';

interface IconsProps {
  name?: string;
  checked?: boolean;
}

export interface CasinoTabsProps {
  tabOptions: {label: string; value: string}[];
  value: string;
  render: (value: string, index: number) => React.ReactNode;
  onChange: (value: string) => void;
}

const CasinoIcons: React.FC<IconsProps> = ({checked = false, name = ''}) => {
  const innerProps = {
    width: '1.5rem',
    height: '1.5rem',
    fill: checked ? '#fff' : theme.fontColor.accent,
  };
  if (name === '' || name.toLowerCase().trim().startsWith('all')) {
    return <AllIcon {...innerProps} style={{color: innerProps.fill}} />;
  }
  if (name.toLowerCase().trim().startsWith('classic')) {
    return <ClassicIcon {...innerProps} style={{color: innerProps.fill}} />;
  }
  if (name.toLowerCase().trim().startsWith('slots')) {
    return <SlotsIcon {...innerProps} style={{color: innerProps.fill}} />;
  }
  if (name.toLowerCase().trim().startsWith('table games')) {
    return <TableGamesIcon {...innerProps} style={{color: innerProps.fill}} />;
  }
  if (name.toLowerCase().trim().startsWith('new games')) {
    return <NewReleasesIcon {...innerProps} />;
  }
  if (name.toLowerCase().trim().startsWith('others')) {
    return <OthersIcon {...innerProps} />;
  }
  if (name.toLowerCase().trim().startsWith('fishing')) {
    return <FishGamesIcon {...innerProps} style={{color: innerProps.fill}} />;
  }
  if (name.toLowerCase().trim().startsWith('live casino')) {
    return <LiveCasinoIcon {...innerProps} style={{color: innerProps.fill}} />;
  }
  return null;
};

export default CasinoIcons;
