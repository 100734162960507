import Text from '@/components/basic/text';
import globalStore from '@/services/global.state';
import theme from '@/style';
import {http, toPriceStr} from '@/utils';
import React from 'react';
import {Image, ImageBackground, ImageRequireSource, View} from 'react-native';
import Animated, {
  Easing,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import {useInnerStyle} from '../home.hooks';
import {useTranslation} from 'react-i18next';

// const scratchTagImg = require('@assets/imgs/home/winner-scratch-tag.webp');
// const casinoTagImg = require('@assets/imgs/home/winner-casino-tag.webp');
// const colorTagImg = require('@assets/imgs/home/winner-color-tag.webp');
// const diceTagImg = require('@assets/imgs/home/winner-dice-tag.webp');
// const liveTagImg = require('@assets/imgs/home/winner-live-tag.webp');
// const sportsTagImg = require('@assets/imgs/home/winner-sports-tag.webp');
const gamePad = 40;
const tagImgs = [
  require('@assets/imgs/home/winner-scratch-tag.webp'),
  require('@assets/imgs/home/winner-casino-tag.webp'),
  require('@assets/imgs/home/winner-color-tag.webp'),
  require('@assets/imgs/home/winner-dice-tag.webp'),
  require('@assets/imgs/home/winner-live-tag.webp'),
  require('@assets/imgs/home/winner-sports-tag.webp'),
];

interface HomeWinningInformationProps {
  version?: number;
}

const HomeWinningInformation: React.FC<HomeWinningInformationProps> = ({
  version,
}) => {
  const {i18n} = useTranslation();
  const translateY = useSharedValue(0);
  const {
    size: {boxContainerWidth},
  } = useInnerStyle();
  const headerWidth = boxContainerWidth;
  const headerHeight = (headerWidth * 52) / 351;
  const [winnerList, setWinnerList] = React.useState<
    {
      name: string;
      amount: string;
      tag?: ImageRequireSource;
      lotteryName: string;
      rank: number;
    }[]
  >([]);

  const listAnimated = () => {
    if (translateY.value <= -48 * (winnerList.length - 6)) {
      // 排行榜默认10条,滚动5条后回到第一条
      setTimeout(() => {
        translateY.value = 0;
        listAnimated();
      }, 4000);
    } else {
      translateY.value = withTiming(translateY.value - 48, {
        duration: 2000,
        easing: Easing.linear,
      });
      setTimeout(() => {
        listAnimated();
      }, 2000);
    }
  };

  React.useEffect(() => {
    if (winnerList.length > 5) {
      translateY.value = 0;
      listAnimated();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [winnerList]);

  React.useEffect(() => {
    http
      .post<
        null,
        {
          bonus: number;
          rank: number;
          userName: string;
          userId: number;
          lotteryName: string;
          gameCode?: 1 | 2 | 3 | 4 | 5 | 6;
        }[]
      >('app/h5/userBonusRankingList', {
        nums: 100,
      })
      .then(res => {
        setWinnerList(
          res.map(v => ({
            name: v.userName + v.userId,
            rank: v.rank,
            amount: toPriceStr(v.bonus, {
              thousands: true,
              showCurrency: false,
              fixed: 0,
            }),
            lotteryName: v.lotteryName,
            tag: v.gameCode ? tagImgs[v.gameCode - 1] : null,
          })),
        );
      });
  }, [version]);

  return (
    <View
      style={[
        theme.margin.lrl,
        theme.margin.btml,
        theme.background.white,
        theme.padding.btml,
      ]}>
      <ImageBackground
        source={require('@assets/imgs/home/information-header.webp')}
        style={[
          {
            width: headerWidth,
            height: headerHeight,
          },
          theme.flex.row,
          theme.flex.centerByCol,
          theme.padding.lrxxl,
        ]}>
        <Image
          source={require('@assets/imgs/home/gold.webp')}
          style={[theme.icon.m, theme.margin.rightxxs]}
        />
        <Text size="medium" blod main>
          {i18n.t('home.title.winningInformation')}
        </Text>
      </ImageBackground>
      <View
        style={[
          theme.flex.col,
          {
            maxHeight: 48 * 5,
          },
          theme.overflow.hidden,
        ]}>
        <Animated.View
          style={[
            // @ts-ignore
            {
              transform: [{translateY: translateY}],
            },
          ]}>
          {winnerList.map((v, i) => {
            const name = v.lotteryName.toLowerCase();
            const requireGameImg = name.includes('dice')
              ? require('@assets/imgs/home/dice.webp')
              : name.includes('color')
              ? require('@assets/imgs/home/color.webp')
              : require('@assets/imgs/home/racecar.webp');
            return (
              <View
                key={i}
                style={[
                  theme.flex.row,
                  theme.flex.centerByCol,
                  theme.padding.tbs,
                  theme.padding.lrl,
                  theme.flex.between,
                  // eslint-disable-next-line react-native/no-inline-styles
                  {
                    borderBottomWidth: i === winnerList.length - 1 ? 0 : 1,
                    borderBottomColor: theme.backgroundColor.lightGrey,
                    height: 56,
                  },
                ]}>
                <View style={[theme.flex.row, theme.flex.centerByCol]}>
                  <Image
                    source={requireGameImg}
                    style={[
                      theme.margin.rights,
                      theme.borderRadius.xs,
                      {
                        width: gamePad,
                        height: gamePad,
                      },
                    ]}
                  />
                  <View>
                    <Text accent>{v.name}</Text>
                    <Text second>{v.lotteryName}</Text>
                  </View>
                </View>
                {v.tag && (
                  <Image
                    style={[
                      // eslint-disable-next-line react-native/no-inline-styles
                      {height: 30, width: 80},
                    ]}
                    source={v.tag}
                  />
                )}
                <View>
                  <Text primary textAlign="right" fontWeight="700">
                    {globalStore.currency}
                    <Text primary fontSize={16} fontWeight="700">
                      {v.amount}
                    </Text>
                  </Text>
                  <Text accent>{i18n.t('home.label.winningAmount')}</Text>
                </View>
              </View>
            );
          })}
        </Animated.View>
      </View>
    </View>
  );
};

export default HomeWinningInformation;
