import globalStore from '@/services/global.state';
import theme from '@/style';
import {BasicObject, SafeAny} from '@types';
import Tag from '@basicComponents/tag';
import {NoticeMap} from '../home.type';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import HomeService from './home-service-old';
import {goTo} from '@/utils';
import React, {useState, useEffect, useRef} from 'react';
import {Image, View} from 'react-native';
import {useLuckySpinModal} from '@/common-pages/luckyspin/luckyspin.hooks';
import HomeRabateAnimationWeb from '../rebate/home-rebate-animation-web';
import HomeRabateAnimation from '../rebate/home-rebate-animation';
import {useInnerStyle} from '../home.hooks';
import {postSpinConfig} from '@/common-pages/luckyspin/luckyspin.service';
// import HomeChat from './home-chat';
import {getSideList} from '../home.service';
// import {Shadow} from 'react-native-shadow-2';
import ExposureWrap from '@/components/business/track-wrap/exposure-wrap';
import {trackClick} from '@/components/business/track-wrap/utils';

const shadow = {
  startColor: 'rgba(0, 0, 0, 0.25)',
  distance: 4,
  offset: [0, 4],
  style: [theme.borderRadius.m, theme.overflow.hidden] as BasicObject[],
} as BasicObject;

if (globalStore.isWeb) {
  shadow.distance = 0;
  delete shadow.offset;
  shadow.style.push({
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  });
}

const HomeSide = ({
  noticeMap,
  onNotice,
}: {
  noticeMap: NoticeMap;
  onNotice?: () => void;
}) => {
  const [drawImage, setDrawImage] = useState<string>();
  const [freeCount, setFreeCount] = useState<number>(0);
  const [spinBatchCount, setSpinBatchCount] = useState(30);
  const [spinBasePrice, setSpinBasePrice] = useState(10);
  const [imgs, setImgs] = useState({} as {[key: string]: string});

  const getImgs = () => {
    getSideList()
      .then(r => {
        /**
         * {
         * "IM_APP":
         * CUSTOMER_SERVICE
         * }
         */
        setImgs(
          r.reduce((pre, cur) => {
            // 存贮im图标
            if (cur.moduleType === 'IM_APP') {
              globalStore.imIcon = cur.defaultImgUrl;
            }
            (pre as SafeAny)[cur.moduleType] = cur.defaultImgUrl;
            return pre;
          }, {}),
        );
      })
      .catch(e => console.log('e', e));
  };
  const onRefreshSpinConfig = () => {
    if (globalStore.token) {
      postSpinConfig(true).then(data => {
        setFreeCount(data?.myFree || 0);
      });
    }
  };
  const doNotice = () => {
    onNotice?.();
    onRefreshSpinConfig();
  };
  const {renderModal: renderSpin, show: spinShow} = useLuckySpinModal({
    drawImage,
    onNotice: doNotice,
    batchCount: spinBatchCount,
    singleAmount: spinBasePrice,
    freeCount: freeCount,
  });
  const {homeSideStyles} = useInnerStyle();
  const login = useRef<boolean>(false);

  const getNavTag = (num?: number) =>
    num && num !== 0 ? (
      <Tag
        style={homeSideStyles.vipNavsItemTag}
        content={num < 0 ? '!' : num > 0 ? num : ''}
      />
    ) : null;

  useEffect(() => {
    getImgs();
    const sub = globalStore.tokenSubject.subscribe(token => {
      login.current = !!token;
      postSpinConfig(!!token)
        .then(data => {
          setDrawImage(data?.image);
          setSpinBasePrice(data?.singleAmount);
          setSpinBatchCount(data?.batchCount);
          setFreeCount(data?.myFree || 0);
          Image.prefetch(data.image).catch(e => {
            console.log(e);
          });
        })
        .catch(e => {
          console.log(e);
        });
    });
    const sub2 = globalStore.doNotices.subscribe(() => {
      doNotice();
    });
    return () => {
      sub.unsubscribe();
      sub2.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <View style={[theme.position.abs, homeSideStyles.wrap]}>
      <ExposureWrap message="HOME_FLOATINGWINDOW_EVENTS_EXPOSURE">
        <NativeTouchableOpacity
          activeOpacity={0.8}
          style={
            !noticeMap.REBATE
              ? [
                  homeSideStyles.luckyspinShadow,
                  homeSideStyles.vipNavsItemImg,
                  homeSideStyles.mb16,
                ]
              : [homeSideStyles.mb16]
          }
          onPress={() => {
            trackClick('HOME_FLOATINGWINDOW_EVENTS_TAP');
            if (!login.current) {
              goTo('Login');
              return;
            }
            goTo('Rebate');
          }}>
          <View>
            {!!noticeMap.REBATE && (
              <View
                style={[
                  theme.position.abs,
                  homeSideStyles.vipNavsItemImg,
                  {
                    top: theme.paddingSize.zorro + 2,
                    left: theme.paddingSize.zorro - 2,
                    backgroundColor: theme.basicColor.dark + '6',
                    borderRadius: homeSideStyles.vipNavsItemImg.height / 2,
                  },
                ]}
              />
            )}
            <View>
              <Image
                style={homeSideStyles.vipNavsItemImg}
                source={require('@assets/imgs/home/rebate.gif')}
              />
            </View>
            {!!noticeMap.REBATE && (
              <View
                style={[
                  theme.position.abs,
                  homeSideStyles.vipNavsItemImg,
                  {
                    top: theme.paddingSize.zorro + 2,
                    left: theme.paddingSize.zorro - 2,
                  },
                ]}>
                {globalStore.isWeb ? (
                  <HomeRabateAnimationWeb />
                ) : (
                  <HomeRabateAnimation />
                )}
              </View>
            )}
            {getNavTag(noticeMap.REBATE)}
          </View>
        </NativeTouchableOpacity>
      </ExposureWrap>
      {false && (
        <NativeTouchableOpacity
          activeOpacity={0.8}
          onPress={() => {
            if (!login.current) {
              goTo('Login');
              return;
            }
            spinShow();
          }}
          style={[
            homeSideStyles.luckyspinShadow,
            homeSideStyles.vipNavsItemImg,
            homeSideStyles.mb16,
          ]}>
          <View style={[theme.flex.col, theme.flex.center]}>
            <View style={[shadow]}>
              <Image
                style={{
                  height: homeSideStyles.luckyspinItemImg.height,
                  width: homeSideStyles.luckyspinItemImg.width,
                }}
                source={require('@assets/imgs/home/luckyspin.gif')}
              />
            </View>
            {getNavTag(noticeMap.LUCKY_SPIN)}
          </View>
        </NativeTouchableOpacity>
      )}
      {/* {!!imgs.IM_APP && <HomeChat img={imgs.IM_APP} />} */}
      {!!imgs.CUSTOMER_SERVICE && (
        <ExposureWrap message="HOME_FLOATINGWINDOW_SERVICE_EXPOSURE">
          <HomeService img={imgs.CUSTOMER_SERVICE} />
        </ExposureWrap>
      )}
      {renderSpin}
    </View>
  );
};
export default HomeSide;
