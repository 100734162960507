import React, {useMemo, useState} from 'react';
import {Image} from 'antd-mobile';
import {ReactComponent as RightArrowIcon} from '@components/assets/icons/casino/right-arrow.svg';
import {ReactComponent as LeftArrowIcon} from '@components/assets/icons/casino/left-arrow.svg';
import {Swiper, SwiperSlide} from 'swiper/react';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import {useTranslation} from 'react-i18next';
import 'swiper/css';

export interface CasinoCategoryProps<T = any> {
  list: T[];
  renderItem: (item: T) => React.ReactNode;
  icon: React.ReactNode;
  title: React.ReactNode;
  swiperSlideWidth?: number;
  swiperSlideClassName?: string;
  onClick: () => void;
}

const CasinoCategory = <T,>({
  list,
  renderItem,
  icon,
  title,
  swiperSlideWidth,
  swiperSlideClassName,
  onClick,
}: CasinoCategoryProps<T>) => {
  const {t} = useTranslation();
  const [swiperInstance, setSwiterInstance] = useState<any>(null);
  const [leftDisabled, setLeftDisabled] = useState<boolean>(true);
  const [rightDisabled, setRightDisabled] = useState<boolean>(list.length < 4);
  const renderIcon = useMemo(() => {
    return typeof icon === 'string' ? (
      <Image width={'1.75rem'} height={'1.75rem'} src={icon} />
    ) : (
      icon
    );
  }, [icon]);
  const renderTitle = useMemo(() => {
    return typeof title === 'string' ? (
      <span className="text-t3 text-sm font-bold capitalize">{title}</span>
    ) : (
      title
    );
  }, [title]);
  const {calcActualSize} = useScreenSize();
  const slidePrev = () => {
    if (swiperInstance) {
      const activeIndex = swiperInstance.activeIndex;
      if (activeIndex === 0) {
        return;
      }
      if (activeIndex > 3) {
        swiperInstance.slideTo(activeIndex - 3);
      } else {
        swiperInstance.slideTo(0);
      }
    }
  };
  const slideNext = () => {
    if (swiperInstance) {
      const activeIndex = swiperInstance.activeIndex;
      if (activeIndex >= list.length - 3) {
        return;
      }
      swiperInstance.slideTo(activeIndex + 3);
    }
  };
  const slideChange = (swiper: any) => {
    const activeIndex = swiper.activeIndex;
    if (activeIndex === 0) {
      setLeftDisabled(true);
    } else {
      setLeftDisabled(false);
    }
    if (activeIndex >= list.length - 3) {
      setRightDisabled(true);
    } else {
      setRightDisabled(false);
    }
  };
  return list.length > 0 ? (
    <div className="flex flex-col gap-2 w-full bg-white">
      <div className="flex flex-row justify-between items-center p-2">
        <div className="flex flex-row items-center gap-2">
          {renderIcon}
          {renderTitle}
        </div>
        <div className="flex flex-row gap-3">
          <div
            onClick={onClick}
            className="bg-[#F5F4F1] flex h-[1.75rem] py-1 pl-2 pr-1 items-center gap-1 rounded"
            style={{
              border: '1px solid rgba(0, 0, 0, 0.16)',
            }}>
            {t('home.label.all')}
            <RightArrowIcon
              style={{
                color: 'rgb(187, 187, 197)',
              }}
            />
          </div>
          <div className="flex flex-row gap-2">
            <div
              className="bg-[#F5F4F1] flex flex-row w-[1.75rem] h-[1.75rem] py-1 items-center justify-center rounded"
              style={{
                border: leftDisabled
                  ? '1px solid rgba(0, 0, 0, 0.04)'
                  : '1px solid rgba(0, 0, 0, 0.16)',
              }}
              onClick={slidePrev}>
              <LeftArrowIcon
                style={{
                  color: leftDisabled
                    ? 'rgba(187, 187, 197, 0.24)'
                    : 'rgb(187, 187, 197)',
                }}
              />
            </div>
            <div
              className="bg-[#F5F4F1] flex flex-row w-[1.75rem] h-[1.75rem] py-1 items-center justify-center rounded"
              style={{
                border: rightDisabled
                  ? '1px solid rgba(0, 0, 0, 0.04)'
                  : '1px solid rgba(0, 0, 0, 0.16)',
              }}
              onClick={slideNext}>
              <RightArrowIcon
                style={{
                  color: rightDisabled
                    ? 'rgba(187, 187, 197, 0.24)'
                    : 'rgb(187, 187, 197)',
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <Swiper
        onSwiper={setSwiterInstance}
        slidesPerView={'auto'}
        spaceBetween={calcActualSize(8)}
        onSlideChange={slideChange}
        className="w-full px-2">
        {list.map((item, index) => (
          <SwiperSlide
            key={index}
            className={swiperSlideClassName}
            style={swiperSlideWidth ? {width: swiperSlideWidth} : {}}>
            {renderItem(item)}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  ) : null;
};

export default CasinoCategory;
